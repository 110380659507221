<template>
  <div class="content_box">
    <!-- 顶部图片 -->
    <div class="head_img_box">
      <el-image :src="headImg">
        <div slot="placeholder" class="image-slot">
          <img :src="loadImg" class="loadImg" alt="" />
        </div>
      </el-image>
      <!-- 使用方法 -->
      <div class="usage_box">
        <div class="title">畅游福冈必备的景点套票，精选多个人气设施，交通套票与美食店家，一周内可以任选三个喜欢的设施，出示电子票券QR code就可以轻松入场体验。</div>
        <div class="syff">
          <div class="syff_left">
            <p></p>
            <p></p>
          </div>
          <div class="txt">使用方法</div>
          <div class="syff_right">
            <p></p>
            <p></p>
          </div>
        </div>
        <div class="neiron">
          ●同设施只可以使用一次，相同设施不可再入场<br>
          ●票劵使用效期为购买日起<span>90天内</span><br>
           EX：<span>7/1</span>购买票券⇒<span>票期:7/1~9/28</span><br>
          ●票券有效期限: 使用第一个设施后，7日内须使用完毕<br>
          EX：<span>7/1</span>开始使用，<span>有效期限至7/7</span><br>
          ●有效期间内可任<span>选3项设施</span><br>
          ●注意: 单次购买複数票劵时，当其中一张票劵开始用，所有票劵也将同步开通 (<span>使用效期7天</span>)<br>
          ●票券开通后，7天的使用效期无法超过票券<span>90天</span>的有效期间。<br>
           EX：票券期限<span>7/1~9/28</span>，若<span>9/27</span>开始使用，其使用效期为<span>9/27~9/28</span><br>
          ●无使用完毕不能兑现<br>
          ●请事先于以下连结确认各设施注意事项・营业时间・公休日情报
        </div>
      </div>
      <!-- 景点介绍 -->
      <div class="main_box" v-for="(item,i) in dataList" :key="i">
        
        <!-- 图片 -->
        <div class="img_box">
          <el-image :src="item.img" lazy>
            <div slot="placeholder" class="image-slot">
              <img :src="loadImg" class="loadImg" alt="" />
            </div>
          </el-image>
        </div>
        
        <!-- 文字 -->
        <div class="text_box">
          <div class="title" v-for="(title,i) in item.title" :key="i" v-html="title"></div>
          <hr>
          <div class="message_box">
            <div class="message_item" v-for="msg in item.message" :key="msg.msgId">
              <div style="margin-bottom:5px">{{msg.title}}</div>
              <div v-if="msg.title!=='注意事项'">
                <div v-for="contentItem in msg.content" :key="contentItem.cid">
                  <span v-for="(cItem,i) in contentItem.text" :key="i">
                    <span v-show="msg.title==='兑换地点' && !contentItem.noColor">{{cItem}}<br></span>
                    <a :style="[{'borderBottom': !contentItem.noColor ? '1px solid' : ''},{color: !contentItem.noColor ? '#219DCC' : ''}]" target="_blank" :href="cItem" v-show="msg.title==='官方网站' && !contentItem.noColor">{{cItem}}<br></a>
                    <p v-show="contentItem.noColor" v-html="cItem"></p>
                  </span>
                </div>
              </div>
              <!-- 注意事项特殊处理 -->
              <!-- :style判断是否要数字前缀 -->
              <div :style="{marginLeft:msg.isDecimal ? '16.5px' : '0px'}" v-else>
                <ul :style="{listStyleType:msg.isDecimal ? 'decimal' : 'none'}">
                  <li v-for="contentItem in msg.content" :key="contentItem.cid">
                    <span v-html="cItem" v-for="(cItem,i) in contentItem.text" :key="i"></span>
                  </li>
                </ul>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 景点介绍 end -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      headImg: require('@/assets/images/hffukuoka/zh/havefun_title_sc.jpg'),
      loadImg: require('@/assets/images/hfkansai/loading.png'),
      dataList: [
        {
          id: 1,
          img: require('@/assets/images/hffukuoka/CasualDining.png'),
          title: ['休闲居酒屋 雪花'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '〒810-0003 福冈県福冈市中央区春吉3-11-19 panorama sukuea博多 1F'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://www.instagram.com/yukihana_fuk/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: false, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['・店内可用餐时间:约3小时。(仅周末・国定假日前日有限时)'] },
                { cid: 2, text: ['・营业时间请事先至官网确认。'] },
                { cid: 3, text: ['・仅限短期旅游的外国旅客可使用。'] },
                { cid: 4, text: ['・每人消费2000日圆以上时可使用1000日圆优惠券。'] },
                { cid: 5, text: ['・有可能会因为店家客满而无法使用的状况，请见谅。'] },
                ]
            }
          ]
        },
        // 
        // {
        //   id: 2,
        //   img: require('@/assets/images/hffukuoka/KoreanstSeet.png'),
        //   title: ['韩国摊贩甜月'],
        //   message: [
        //     {
        //       msgId: 1,
        //       title: '兑换地点',
        //       content: [{ cid: 1, text: ['福冈市博多区下川端町1-331-2F'] }]
        //     },
        //     {
        //       msgId: 2,
        //       title: '官方网站',
        //       content: [{ cid: 1, text: ['https://darubamu.owst.jp/zh-cn/'] }]
        //     },
        //     {
        //       msgId: 3,
        //       title: '注意事项',
        //       isDecimal: false, //是否启用ul的数字前缀
        //       content: [
        //         { cid: 1, text: ['・店内可用餐时间:约2小时30分。'] },
        //         { cid: 2, text: ['・营业时间请事先至官网确认。'] },
        //         { cid: 3, text: ['・仅限短期旅游的外国旅客可使用。'] },
        //         { cid: 4, text: ['・每人消费2000日圆以上时可使用1000日圆优惠券。'] },
        //         { cid: 5, text: ['・有可能会因为店家客满而无法使用的状况，请见谅。'] },
        //       ]
        //     }
        //   ]
        // },
        // 
        {
          id: 3,
          img: require('@/assets/images/hffukuoka/HaruyoshiBar.png'),
          title: ['春吉酒吧 Rio'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: ['福冈市中央区春吉2-4-11 Riviere Champ104'] }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [{ cid: 1, text: ['https://www.instagram.com/haruyoshi_bar_rio/?igshid=YmMyMTA2M2Y%3D'] }]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: false, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['・店内可用餐时间:约2小时。'] },
                { cid: 2, text: ['・营业时间请事先至官网确认。'] },
                { cid: 3, text: ['・仅限短期旅游的外国旅客可使用。'] },
                { cid: 4, text: ['・每人消费2000日圆以上时可使用。'] },
                { cid: 5, text: ['・有可能会因为店家客满而无法使用的状况，请见谅。'] },
              ]
            }
          ]
        },
      ]
    }
  },
  created () {
    // this.$router.push('/hfPage?id=HFFukuoka&l=sc')
    window.open('https://www.travelcontentsapp.com/have-fun/have-fun-in-fukuoka-1-week-free-pass/sc', '_self')
  }
}
</script>
<style lang="scss" scoped>
.content_box {
  font-family: "Genseki";
  position: relative;
  width: 100%;
  height: 100%;
  // 使用方法
  .usage_box {
    background-color: #FFFBA0;
    padding: 18px 20px;
    margin: 20px 15px;
    color: #1A1311;
    font-weight: 700;
    margin-top: 0;
    .title{
      font-size: 13px;
      line-height: 19px;
    }
    .syff {
      display: flex;
      justify-content: space-between;
      margin: 18px 0;
      .txt {
        font-size: 21px;
        margin: 0 8.5px;
      }

      .syff_left,
      .syff_right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p{
          display: inline-block;
          height: 1px;
          width: 100%;
          background-color: #707070;
          margin-bottom: 2px;
        }
      }
    }
    .neiron {
      line-height: 21px;
      font-size: 12px;
      span{
        color: #FA6460;
      }
    }
  }
  .main_box {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 26px;
    .img_box {
      width: 100%;
      box-shadow: 6px 0px 6px rgba(0, 0, 0, 0.16);
    }
    
    .text_box {
      position: relative;
      margin-top: 22px;
      padding: 0 10px;
      // width: 90%;
      // padding-left: 24px;
      // padding-right: 17px;
      .title {
        font-size: 17px;
        font-weight: bolder;
        color: #876b57;
        margin: 5px 0;
        /deep/span {
          color:#ff0000;
        }
      }
      .message_box {
        font-size: 13px;
        line-height: 17px;
        font-weight: bold;
        margin-top: 5px;
        .message_item:not(:first-child) {
          margin-top: 22px;
        }
      }
    }
  }
}
.loadImg {
  width: 100%;
  height: auto;
}
hr {
  border: none;
  height: 1px;
  background-color: #707070;
}
a:link {
  color: #000;
}
a:visited {
  color: #000;
}
a:hover {
  color: #000;
  border-bottom: 1px solid;
}
a:active {
  color: #000;
}
</style>